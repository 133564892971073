.App{
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.center{
  height: 100%;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section1{
  background-image: url("https://pajaczekstorage.blob.core.windows.net/background/4844434.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.section2{
  background-image: url("https://pajaczekstorage.blob.core.windows.net/background/23.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.section3{
  background-image: url("https://pajaczekstorage.blob.core.windows.net/background/low_poly_abstract_banner_design_2210.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.loader-logo{
  width: 85%;
}

hr {
  border:none;
  height: 20px;
  width: 90%;
  height: 50px;
  margin-top: 0;
  border-bottom: 1px solid #1f1209;
  box-shadow: 0 20px 20px -20px #333;
  margin: -50px auto 10px;
}

.whitesmoke{
  background: whitesmoke;
}

.gainsboro{
  background: gainsboro;
}

.inline{
  display: inline;
}

.left-margin{
  margin-left: 15px;
}
