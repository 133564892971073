body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.model{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  transition:opacity .4s easy, visibility .4s easy, transform .5s easy-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

.model.open{
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.model img{
  top: 120px;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
}

.model.open img{
  top: 120px;
}

.model.open svg{
  position: fixed;
  top: 10px;
  right: 10px;
  width: 2rem;
  height: 2rem;
  padding: 5px;
  background-color: rgba(0,0,0,0.4);
  color: white;
  cursor: pointer;
}

.responsive {
  width: 100%;
  max-width: 400px;
  height: auto;
}

@media (max-width: 720px) {
  .gallery{
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    column-gap: 5px;
    break-inside: avoid;
    -webkit-column-width: 50%;
    -moz-column-width: 50%;
    column-width: 50%;
    padding: 0 0px;
  }
}
